import { PlayerClientModel } from '@sport1/types/sportData'
import { TickerPlayerProps } from '@sport1/news-styleguide/TickerPlayer'
import getPlayerName from '@/utils/sportsData/getPlayerName'

const buildPlayer = (
    player: PlayerClientModel,
    href: string,
    statusIcon?: TickerPlayerProps['playerStatusIcon']
): TickerPlayerProps => ({
    playerName: getPlayerName(player, 'firstname lastname'),
    playerImageUrl: player.imageUrl || '',
    teamLogoUrl: player.team?.imageUrl || '',
    teamName: player.team?.name || '',
    playerStatusIcon: statusIcon,
    href,
})

export default buildPlayer
