import React, { VFC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeamStructureList from '@sport1/news-styleguide/TeamStructureList'
import { TeamOfficialClientModel } from '@sport1/types/sportData'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import getPlayerName from '@/utils/sportsData/getPlayerName'

type TrainerProps = {
    trainer: TeamOfficialClientModel
    testID?: string
}

const Trainer: VFC<TrainerProps> = ({ trainer, testID }) => (
    <>
        <NonFlexingContainer
            paddingTop="spacing-9"
            paddingBottom="spacing-6"
            testID={`${testID}-container`}
        >
            <CardHeadline text="Trainer" cardHeadlineType="cardHeadlineS" testID="trainer" />
        </NonFlexingContainer>
        <TeamStructureList
            testID="team-structure-list"
            type="TEAM_COACH"
            name={getPlayerName(trainer, 'firstname lastname')}
            imageUrl={trainer.imageUrl}
        />
    </>
)

export default memo(Trainer)
