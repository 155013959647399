import React, { useCallback, useEffect } from 'react'
import { CommentaryModel } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import commentaryStateReducer, { CommentaryData, initialCommentaryState } from '../state'
import getJson from '@/utils/fetch/getJson'
import Config from '@/utils/Config'
import getSportIdentifier from '@/utils/match/getSportIdentifier'

type UseCommentaryDataHook = CommentaryData & {
    fetchNextCommentaries: () => void
    checkForNewCommentaries: () => void
    refreshCommentaries: (silent?: boolean) => void
}

type UseCommentaryDataProps = {
    url: string
    match?: MatchClientModel
}

function useCommentaryData({ url, match }: UseCommentaryDataProps): UseCommentaryDataHook {
    const isFetching = React.useRef(false)
    const page = React.useRef(0)
    const [commentaryData, dispatcher] = React.useReducer(
        commentaryStateReducer,
        initialCommentaryState
    )

    const fetchFirstCommentaries = useCallback(() => {
        if (!isFetching.current) {
            isFetching.current = true
            getJson(url.replace('{page}', `${page.current}`)).then(response => {
                dispatcher({
                    type: 'INSERT_COMMENTARY',
                    commentaries: response.ticker,
                    hasNextPage: response.ticker.length > 0,
                })
                isFetching.current = false
            })
        }
    }, [url])

    const fetchNextCommentaries = useCallback(() => {
        if (commentaryData.hasNextPage && !isFetching.current) {
            isFetching.current = true
            getJson(url.replace('{page}', `${++page.current}`)).then(response => {
                dispatcher({
                    type: 'CONCAT_COMMENTARY',
                    hasNextPage: response.ticker.length > 0,
                    commentaries: response.ticker,
                })
                isFetching.current = false
            })
        }
    }, [commentaryData.hasNextPage, url])

    const checkForNewCommentaries = useCallback(() => {
        if (match) {
            const sportIdentifier = getSportIdentifier(match)
            getJson(
                `${Config.CMS_API}/v2/de/${sportIdentifier}/ticker/${match.id}/commentary/latest`
            ).then(response => {
                if (response.ticker) {
                    const latestStateCommentary = commentaryData.commentaries.find(
                        c => c.type === 'COMMENTARY'
                    ) as CommentaryModel
                    const commentary = response.ticker[0]?.commentary
                    const isCommentaryNotEqual =
                        commentary?.id !== latestStateCommentary?.commentary.id
                    if (isCommentaryNotEqual) {
                        dispatcher({
                            type: 'NEW_COMMENTARY_AVAILABLE',
                        })
                    }
                }
            })
        }
    }, [commentaryData, match])

    const refreshCommentaries = useCallback(() => {
        page.current = 0
        dispatcher({
            type: 'REFRESH_COMMENTARY',
        })
        fetchFirstCommentaries()
    }, [fetchFirstCommentaries])

    useEffect(() => {
        if (page.current === 0 && !commentaryData.commentaries?.length) {
            fetchFirstCommentaries()
        }
    }, [commentaryData.commentaries?.length, fetchFirstCommentaries])

    return {
        ...commentaryData,
        fetchNextCommentaries,
        refreshCommentaries,
        checkForNewCommentaries,
    }
}

export default useCommentaryData
