import React, { FunctionComponent, memo } from 'react'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

const EmptyMatchStatistics: FunctionComponent = () => (
    <Font
        testID="empty-match-statistics"
        fontVariant="Body-L"
        nextFontFamilyVariant={sport1Regular.style.fontFamily}
        fontFamilyVariant="Sport1-Regular"
        paddingX={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-none']}
        paddingBottom="spacing-7"
    >
        Für dieses Spiel gibt es bisher noch keine Statistiken.
    </Font>
)

export default memo(EmptyMatchStatistics)
