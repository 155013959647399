import React, { ReactElement, useCallback, useMemo } from 'react'
import HorizontalScrollView from '@sport1/news-styleguide/HorizontalScrollView'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { Spacing } from '@sport1/news-styleguide/LayoutTypes'
import IconButtonArrow from './IconButtonArrow'
import { FlatListRefType, HorizontalSliderProps } from './types'
import { SizeTypes } from '@/types/size'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { useIsOnTeamPage } from '@/helpers/teamHelper'
import useScreenType from '@/hooks/useScreenType'

export const MATCH_ELEMENTS_CARD_ITEM_WIDTH = 340

const INDEX_UPCOMING_MATCH = 2

const SpaceMap: Map<Spacing, number> = new Map([
    ['none', 0],
    ['small', 8],
    ['standard', 16],
    ['medium', 24],
    ['big', 32],
])

const HorizontalSlider = <T extends {}>({
    items,
    arrowTopOffset,
    scrollOffset = 300,
    keyExtractor,
    renderItem,
    spaceX = 'big',
    backgroundColor,
    horizontalScrollbarEnabled,
    horizontalScrollbarInsets,
    itemWidth,
    testID,
    onViewableItemsChanged,
    bottom,
    buttonVariant,
    onPressArrow,
    arrowMargin,
    onScroll: onScrollCallback,
}: HorizontalSliderProps<T>): ReactElement => {
    const { currentDevice } = useMediaQueryContext()
    const flatListRef = React.useRef<FlatListRefType | null>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const offsetLeft = React.useRef(0)
    const maxOffset = React.useRef(0)
    const [visibleArrows, setVisibleArrows] = React.useState<'left' | 'right' | 'all' | 'none'>(
        'none'
    )

    const { isMobileDevice } = useScreenType()

    React.useEffect(() => {
        const containerWidth = containerRef.current?.getBoundingClientRect?.().width ?? 0
        const leftRightSpace =
            SpaceMap.get(Array.isArray(spaceX) ? spaceX[spaceX.length - 1] : spaceX) || 0

        let scrollWidth: number
        if (itemWidth !== undefined) {
            const spaceBetween = SpaceMap.get('standard') || 0
            scrollWidth =
                itemWidth * items.length + spaceBetween * (items.length - 1) + leftRightSpace * 2
        } else {
            scrollWidth = (flatListRef.current?.scrollWidth ?? 0) + leftRightSpace * 2
        }

        maxOffset.current = scrollWidth - containerWidth
    }, [itemWidth, items.length, spaceX, containerRef, flatListRef])

    const isOnTeamPage = useIsOnTeamPage()

    // distance between MatchElementCard matches
    const SPACING_X = useMemo(() => (currentDevice === SizeTypes.MOBILE ? 16 : 24), [currentDevice])

    // with matchCounter only matchdays on the team page overview is selected / animated
    const matchCounter = (items as unknown as Array<{ type: string } | undefined>).filter(
        i => i?.type === 'MATCH_ELEMENT'
    ).length

    const maxOffsetPaddingRight = matchCounter ? 64 : 2

    const checkArrows = useCallback(
        (offset: number): void => {
            if (
                isMobileDevice() ||
                maxOffset.current <= 0 ||
                // this case checks for team page overview tab, matchdays and an inactive horizontal slider
                (isOnTeamPage &&
                    isMobileDevice() &&
                    matchCounter &&
                    flatListRef.current?.scrollWidth && // available screen space
                    flatListRef.current.scrollWidth + matchCounter * SPACING_X >
                        maxOffset.current +
                            matchCounter * (MATCH_ELEMENTS_CARD_ITEM_WIDTH + SPACING_X)) // actually needed space
            ) {
                return setVisibleArrows('none')
            }

            if (offset === 0) {
                setVisibleArrows('right')
            } else if (offset >= maxOffset.current - maxOffsetPaddingRight) {
                setVisibleArrows('left')
            } else {
                setVisibleArrows('all')
            }
        },
        [
            flatListRef,
            matchCounter,
            maxOffset,
            maxOffsetPaddingRight,
            isOnTeamPage,
            isMobileDevice,
            SPACING_X,
        ]
    )

    const scrollTo = useCallback(
        (offset: number) => {
            const actualOffset = Math.max(
                0,
                Math.min(maxOffset.current, offsetLeft.current + offset)
            )
            flatListRef.current?.scrollToOffset({ offset: actualOffset, animated: true })
            onPressArrow && onPressArrow()
        },
        [maxOffset, onPressArrow]
    )

    const onScroll = useCallback(
        ({ scrollLeft = 0 }: { scrollLeft?: number }): void => {
            offsetLeft.current = scrollLeft
            checkArrows(scrollLeft)
            onScrollCallback && onScrollCallback({ scrollLeft })
        },
        [checkArrows, onScrollCallback]
    )

    const onToggleArrows = (show: boolean) => () => {
        if (show) {
            checkArrows(offsetLeft.current)
        } else {
            setVisibleArrows('none')
        }
    }

    /*
     * automatically scrolls to the next upcoming match
     * if it is on team page overview tab,
     * if there is an upcoming match available
     * and if there is enough space for the upcoming match
     * to be fully displayed on the very left side of the screen
     * maxOffset.current := actually needed space
     */

    React.useEffect(() => {
        isOnTeamPage &&
        matchCounter > INDEX_UPCOMING_MATCH &&
        flatListRef.current?.scrollWidth &&
        maxOffset.current > INDEX_UPCOMING_MATCH * (MATCH_ELEMENTS_CARD_ITEM_WIDTH + SPACING_X)
            ? flatListRef.current?.scrollToOffset({
                  offset: INDEX_UPCOMING_MATCH * (MATCH_ELEMENTS_CARD_ITEM_WIDTH + SPACING_X),
                  animated: true,
              })
            : null
    }, [currentDevice, flatListRef, isOnTeamPage, matchCounter, maxOffset, SPACING_X])

    return (
        <div
            className="relative"
            ref={containerRef}
            onMouseEnter={onToggleArrows(true)}
            onMouseLeave={onToggleArrows(false)}
        >
            {['all', 'left'].includes(visibleArrows) ? (
                <IconButtonArrow
                    icon="scroll-left"
                    top={arrowTopOffset}
                    bottom={bottom ?? 100}
                    onPress={() => scrollTo(-scrollOffset)}
                    testID={`${testID}-left-arrow`}
                    buttonVariant={buttonVariant}
                    arrowMargin={arrowMargin}
                />
            ) : null}
            {['all', 'right'].includes(visibleArrows) ? (
                <IconButtonArrow
                    icon="scroll-right"
                    top={arrowTopOffset}
                    bottom={bottom ?? 100}
                    onPress={() => scrollTo(scrollOffset)}
                    testID={`${testID}-right-arrow`}
                    buttonVariant={buttonVariant}
                    arrowMargin={arrowMargin}
                />
            ) : null}
            <NonFlexingContainer overflow="hidden" testID={testID}>
                <HorizontalScrollView<T>
                    innerRef={flatListRef}
                    items={items}
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                    onScroll={onScroll}
                    spaceBetween="standard"
                    spaceX={spaceX}
                    backgroundColor={backgroundColor}
                    horizontalScrollbarEnabled={horizontalScrollbarEnabled}
                    horizontalScrollbarInsets={horizontalScrollbarInsets}
                    onViewableItemsChanged={onViewableItemsChanged}
                />
            </NonFlexingContainer>
        </div>
    )
}

export default HorizontalSlider
