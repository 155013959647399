import React, { memo, useEffect, useState, VFC } from 'react'
import { AdPlacementType, AdProps, GenericComponentProps } from '@sport1/types/web'
import { LineupInfoClientModel } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import CompetitionHeader from '@sport1/news-styleguide/CompetitionHeader'
import DisplayAd from '@/components/Ads/AdSlot/DisplayAd'
import EmptyLineUp from '@/components/LineUp/Empty'
import LineupField from '@/components/LineUp/Field'
import MinifiedLineUp from '@/components/LineUp/Minified'
import LineupTeamInformation from '@/components/LineUp/TeamInformation'
import TeamSelection from '@/components/LineUp/TeamSelection'
import PlaceHolderBox from '@/components/PlaceHolderBox'
import { useCalculateAdSlotPlacement } from '@/hooks/useCalculateAdSlotPlacement'
import useFetch from '@/hooks/useFetch'
import { createTeamHref } from '@/utils/navigation/Navigator'

const LineUp: VFC<
    Omit<GenericComponentProps, 'contentType' | 'type' | 'params'> & { ad?: AdProps }
> = ({ contentUrl, position, ad }) => {
    const { error, data } = useFetch<LineupInfoClientModel>(contentUrl)
    const { homeTeam, awayTeam } = data?.lineUp || {}
    const hasFormation = homeTeam?.formation?.length && awayTeam?.formation?.length
    const [adInfo, setAdInfo] = useState({})
    const { calculateName } = useCalculateAdSlotPlacement()

    useEffect(() => {
        setAdInfo(calculateName(AdPlacementType.MREC))
    }, [calculateName])

    if (!error && !data) {
        return <PlaceHolderBox height={500} />
    }

    if (position === 'right') {
        return homeTeam && awayTeam ? (
            <MinifiedLineUp
                display={['none', 'none', 'none', 'flex']}
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                hasFormation={!!hasFormation}
            />
        ) : null
    }

    if (error || !data?.lineUp || !homeTeam || !awayTeam) {
        return <EmptyLineUp />
    }

    return (
        <NonFlexingContainer
            horizontal
            marginX={['spacing-none', 'spacing-none', 'spacing-9', 'spacing-none']}
            flexWrap={['wrap', 'wrap', 'nowrap']}
            testID="line-up-container-0"
        >
            <FlexingContainer
                display={['none', 'none', 'none', 'flex']}
                marginRight="spacing-9"
                testID="line-up-container-1"
            >
                <CompetitionHeader
                    imageUrl={homeTeam.imageUrl}
                    leagueName={homeTeam.name || 'Heim'}
                    href={createTeamHref(homeTeam)}
                    testID="line-up-competition-header-0"
                />
                <LineupTeamInformation
                    team={homeTeam}
                    showFirstEleven={!hasFormation}
                    testID="line-up-team-information-0"
                />
            </FlexingContainer>
            {hasFormation ? (
                <NonFlexingContainer width={['100%', '100%', 375]} testID="line-up-container-2">
                    <LineupField homeTeam={homeTeam} awayTeam={awayTeam} />
                    {ad ? (
                        <DisplayAd
                            placeholderType={AdPlacementType.MREC}
                            marginRight="spacing-none"
                            marginTop="spacing-4"
                            marginX={['spacing-6', 'spacing-6', 'spacing-none']}
                            {...adInfo}
                        />
                    ) : null}
                </NonFlexingContainer>
            ) : null}
            <FlexingContainer
                display={['none', 'none', 'none', 'flex']}
                marginLeft="spacing-9"
                testID="line-up-container-3"
            >
                <CompetitionHeader
                    imageUrl={awayTeam.imageUrl}
                    leagueName={awayTeam.name || 'Auswärts'}
                    href={createTeamHref(awayTeam)}
                    testID="line-up-competition-header-1"
                />
                <LineupTeamInformation
                    team={awayTeam}
                    showFirstEleven={!hasFormation}
                    testID="line-up-team-information-1"
                />
            </FlexingContainer>
            <FlexingContainer
                display={['flex', 'flex', 'flex', 'none']}
                marginLeft={['spacing-7', 'spacing-7', 'spacing-9']}
                marginRight={['spacing-7', 'spacing-7', 'spacing-none']}
                marginTop={['spacing-6', 'spacing-6', 'spacing-none']}
                testID="line-up-container-4"
            >
                <TeamSelection
                    homeTeam={homeTeam}
                    awayTeam={awayTeam}
                    showFirstEleven={!hasFormation}
                />
            </FlexingContainer>
        </NonFlexingContainer>
    )
}

export default memo(LineUp)
