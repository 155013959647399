import React, { ReactElement } from 'react'
import { MatchGroupsModel } from '@sport1/types/web'
import GameplanMatchDate from '../MatchDate'
import GameplanMatchGroup from '../MatchGroup'
import GameplanMatch from '../Match'
import { GetMatchItemsParams } from './getMatchComponents'

const getBasicGameplanMatchItems = ({
    matchGroups,
}: Pick<GetMatchItemsParams, 'matchGroups'>): ReactElement[] => {
    const matchElements: ReactElement[] = []
    const groups = matchGroups as MatchGroupsModel[]
    groups.map(({ date, matchGroups }, index) => {
        matchElements.push(<GameplanMatchDate key={date} date={date} />)
        matchGroups.map(({ groupName, matches }, matchGroupsIndex) => {
            if (groupName) {
                matchElements.push(
                    <GameplanMatchGroup key={`${groupName}-${index}`} groupName={groupName} />
                )
            }
            matches?.map((match, matchesIndex) => {
                matchElements.push(
                    <GameplanMatch
                        spaceBottom={
                            matchGroupsIndex === matchGroups.length - 1 &&
                            matchesIndex === matches.length - 1
                                ? undefined
                                : 'standard'
                        }
                        key={`gameplan-match-${match.id}`}
                        match={match}
                    />
                )
            })
        })
    })

    return matchElements
}

export default getBasicGameplanMatchItems
