import React, { FunctionComponent, useMemo, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TickerCommentary from '@sport1/news-styleguide/TickerCommentary'
import { TickerPlayerProps } from '@sport1/news-styleguide/TickerPlayer'
import { CommentaryClientModel } from '@sport1/types/sportData'
import getPlayerStatusIcon from './utils/getPlayerStatusIcon'
import buildPlayer from './utils/buildPlayer'
import getTimeProps from './utils/getTimeProps'
import getHeadlineProps from './utils/getHeadlineProps'
import { createPlayerInfoHref } from '@/utils/navigation/Navigator'

type Props = {
    commentary: CommentaryClientModel
}

const EVENT_WITH_PLAYER: CommentaryClientModel['type'][] = [
    'YELLOW_CARD',
    'SECOND_YELLOW_CARD',
    'RED_CARD',
    'SUBSTITUTION',
    'GOAL',
    'OWN_GOAL',
    'PENALTY_SAVED',
    'PENALTY_WON',
    'PENALTY_LOST', // Should we include those
    'PENALTY_MISS',
    'PENALTY_GOAL',
]

const Commentary: FunctionComponent<Props> = ({ commentary }) => {
    const { matchMinute, message } = commentary

    const tickerPlayers = useMemo<TickerPlayerProps[]>((): TickerPlayerProps[] => {
        const players: TickerPlayerProps[] = []
        const { player1, player2, type } = commentary
        if (type && EVENT_WITH_PLAYER?.includes(type)) {
            if (player1) {
                players.push(
                    buildPlayer(
                        player1,
                        createPlayerInfoHref(player1),
                        getPlayerStatusIcon({ type, player1 })
                    )
                )
            }
            if (player2) {
                players.push(
                    buildPlayer(
                        player2,
                        createPlayerInfoHref(player2),
                        getPlayerStatusIcon({ type, player2 })
                    )
                )
            }
        }
        return players
    }, [commentary])

    if (!message) {
        return null
    }

    return (
        <NonFlexingContainer>
            <TickerCommentary
                key={commentary?.id}
                testID={`commentary-${commentary?.id}`}
                timeProps={
                    matchMinute && matchMinute !== ' ' ? getTimeProps(matchMinute) : undefined
                }
                headlineProps={getHeadlineProps(commentary)}
                text={message}
                tickerPlayers={tickerPlayers}
                hideLine={
                    commentary?.period === 'PRE_MATCH' || commentary?.period === 'END_OF_MATCH'
                }
            />
        </NonFlexingContainer>
    )
}

export default memo(Commentary)
