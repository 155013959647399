import React, { memo, VFC } from 'react'
import { CompetitionGameplanModel } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import getCompetitionId from './utils/getCompetitionId'
import getMatchComponents from './utils/getMatchComponents'
import useFetch from '@/hooks/useFetch'
import CompetitionGamePlanError from '@/components/CompetitionGamePlan/Error'
import ActionButtons from '@/components/ActionButtons'
import { createGameplanHref } from '@/utils/navigation/Navigator'
import { useNavigation } from '@/utils/navigation/Standard'
import OddsDisclaimer from '@/components/OddsDisclaimer'
import PlaceHolderBox from '@/components/PlaceHolderBox'

type CompetitionGamePlanProps = {
    contentUrl: string
}

const CompetitionGamePlan: VFC<CompetitionGamePlanProps> = ({ contentUrl }) => {
    const { data, error } = useFetch<CompetitionGameplanModel>(contentUrl)
    const { navigation } = useNavigation()

    if (error || (data && !data.matchGroups?.length)) {
        return <CompetitionGamePlanError />
    }

    if (!data) {
        return <PlaceHolderBox height={500} />
    }

    const competitionId = data.matchGroups ? getCompetitionId(data.matchGroups) : undefined

    return (
        <NonFlexingContainer
            backgroundColor="pearl"
            testID={`gameplan_${competitionId}`}
            paddingX={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-none']} // vice-versa to Standard/Liveticker/index.tsx, paddingX
        >
            {getMatchComponents({
                matchGroups: data.matchGroups,
            })}
            <OddsDisclaimer textAlign="right" marginTop="spacing-2" />
            <ActionButtons
                testID="action-buttons"
                marginTop="spacing-7"
                marginBottom="spacing-7"
                actionButtons={[
                    {
                        title: 'Spielplan',
                        href: createGameplanHref({
                            tag: navigation?.tag,
                            competitionId: getCompetitionId(data.matchGroups),
                        }),
                    },
                ]}
            />
        </NonFlexingContainer>
    )
}

export default memo(CompetitionGamePlan)
