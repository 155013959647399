import React, { FC, memo } from 'react'
import { MatchClientModel } from '@sport1/types/sportData'
import { HeadlineProps } from '@sport1/news-styleguide/MatchCard'
import SportEvent from '@/components/SportEvent'
import { useNavigation } from '@/utils/navigation/Standard'
import { MatchOverlayProvider } from '@/utils/overlay/MatchOverlayProvider'

type GameplanMatchProps = {
    match: MatchClientModel
    headline?: HeadlineProps
    spaceBottom?: 'standard'
}

const GameplanMatch: FC<GameplanMatchProps> = props => {
    const { navigation } = useNavigation()

    return (
        <MatchOverlayProvider>
            <SportEvent
                {...props}
                // TODO: we can not update bet-service-client because of sport1-components, maybe using S1_WIDGETS like in the App?
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                trackingCreation="inline_spieltag-liveticker" // TODO: make generic if Gameplan will be implemented
                tag={navigation?.tag}
                trackingInteractionLabel="liveticker"
                scope="matchday"
            />
        </MatchOverlayProvider>
    )
}

export default memo(GameplanMatch)
