import React, { FC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LineupTeamClientModel, PlayerWithEventsClientModel } from '@sport1/types/sportData'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import LineupPlayer from '../Player'

type GoalkeeperProps = Pick<LineupTeamClientModel, 'teamKitColor'> & {
    position?: 'relative' | 'absolute'
    top?: number
    bottom?: number
    width: ResponsiveType<PixelType | number | PercentageType>
    goalkeeper: PlayerWithEventsClientModel
}

const GoalKeeper: FC<GoalkeeperProps> = ({
    position,
    top,
    bottom,
    width,
    goalkeeper,
    teamKitColor,
}) => {
    return (
        <NonFlexingContainer
            position={position}
            centerHorizontal
            width={width}
            top={top}
            bottom={bottom}
            zIndex={2}
            testID="formation-half-goalkeeper-container"
        >
            <LineupPlayer
                player={goalkeeper}
                teamKitColor={teamKitColor}
                testID="formation-half-goalkeeper"
            />
        </NonFlexingContainer>
    )
}

export default memo(GoalKeeper)
