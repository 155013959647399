import React, { FC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Chip from '@sport1/news-styleguide/Chip'

type TeamFormationProps = {
    position: 'relative' | 'absolute'
    top?: number
    bottom?: number
    left?: number
    zIndex?: number
    system: string
    teamImageUrl?: string
    testID?: string
}

const TeamFormation: FC<TeamFormationProps> = ({
    position,
    top,
    bottom,
    left,
    zIndex,
    system,
    teamImageUrl,
    testID,
}) => (
    <NonFlexingContainer
        position={position}
        top={top}
        bottom={bottom}
        left={left}
        zIndex={zIndex}
        testID={testID}
    >
        <Chip imageUrl={teamImageUrl} text={system} inactiveBackgroundColor="BLACK" />
    </NonFlexingContainer>
)

export default memo(TeamFormation)
