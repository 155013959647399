import React, { FunctionComponent } from 'react'
import {
    AdPlacementType,
    AdProps,
    ComponentType,
    GenericComponentProps,
    GenericContentType,
    GenericResultTeaserModel,
    GenericStandingsModel,
    GenericTeamInfoModel as TeamInfoProps,
    LayoutProps,
} from '@sport1/types/web'
import LineUp from '@/components/LineUp'
import LivetickerCommentaries from '@/components/LivetickerCommentaries'
import MatchStatistics from '@/components/MatchStatistics'
import Standings, { REWORKED_STANDINGS } from '@/components/Standings'
import CompetitionGamePlan from '@/components/CompetitionGamePlan'
import TeamsOverview from '@/components/TeamsOverview'
import StandingsGeneric from '@/components/StandingsGeneric'
import ResultTeaserGeneric from '@/components/ResultTeaserGeneric'
import { IndexedComponent } from '@/utils/content/indexCompontentTypes'
import TeamInfo from '@/components/TeamInfo'

const GenericComponent: FunctionComponent<
    Omit<GenericComponentProps, 'type'> & {
        layoutData?: LayoutProps
        isRightColumn?: boolean
    }
> = ({ contentType, position, layoutData, ...rest }) => {
    switch (contentType) {
        case GenericContentType.MATCH_LINEUP:
            /** last MREC of the layout should be shown inside of the LineUp on desktop */
            const mrecs = layoutData?.components?.filter(
                c => c.type === ComponentType.AD && c.ad.placement === AdPlacementType.MREC
            )
            return (
                <LineUp
                    ad={mrecs?.length ? (mrecs[mrecs.length - 1] as AdProps) : undefined}
                    position={position}
                    {...rest}
                />
            )
        case GenericContentType.MATCH_STANDINGS:
            return (
                <Standings
                    layoutMode={position === 'right' ? 'RIGHT_COLUMN' : 'RESPONSIVE'}
                    display={position === 'right' ? ['none', 'none', 'none', 'flex'] : undefined}
                    {...rest}
                />
            )
        case GenericContentType.MATCH_STATISTICS:
            return <MatchStatistics {...rest} />
        case GenericContentType.MATCH_TICKER:
            return <LivetickerCommentaries {...rest} layout={layoutData} />
        case GenericContentType.COMPETITION_GAMEPLAN:
            return <CompetitionGamePlan {...rest} />
        case GenericContentType.TEAMS_OVERVIEW:
            return <TeamsOverview {...rest} />
        case GenericContentType.STANDINGS:
            const standingProps = rest as GenericComponentProps & GenericStandingsModel
            return REWORKED_STANDINGS.includes(standingProps.params?.sportIdentifier) ? (
                <StandingsGeneric {...standingProps} />
            ) : null
        case GenericContentType.RESULT_TEASER:
            const { nthOfType, ...props } = rest as GenericComponentProps &
                GenericResultTeaserModel &
                Pick<IndexedComponent, 'nthOfType'>
            return <ResultTeaserGeneric componentIndex={nthOfType} {...props} />
        case GenericContentType.TEAM_INFO:
            if ('additionalData' in rest) {
                const teamInfoProps = rest as Pick<
                    TeamInfoProps & GenericComponentProps,
                    'additionalData' | 'componentKey'
                >
                return <TeamInfo {...teamInfoProps} />
            }
            return null
    }

    return null
}

export default GenericComponent
