import { ReactElement } from 'react'
import { MarketType } from '@sport1/bets-service-client'
import { MatchGroupModel, MatchGroupsModel, TagProps, TrackingDataProps } from '@sport1/types/web'
import getBasicGameplanMatchItems from './getBasicGameplanMatchItems'
import getPlayoffGameplanMatchItems from './getPlayoffGameplanMatchItems'

export type GetMatchItemsParams = {
    matchGroups: MatchGroupsModel[] | MatchGroupModel[]
    marketType: MarketType
    tracking?: TrackingDataProps[]
    tags?: TagProps[]
    hasMultipleSkyButtons?: boolean
    competitionId?: string
    selectedMatchDay?: { label: string; value: string }
}

const getMatchComponents = ({
    matchGroups = [],
    selectedMatchDay,
}: Pick<GetMatchItemsParams, 'matchGroups' | 'selectedMatchDay'>): ReactElement[] => {
    if (matchGroups[0] && 'matchGroups' in matchGroups[0]) {
        return getBasicGameplanMatchItems({
            matchGroups,
        })
    }
    return getPlayoffGameplanMatchItems({
        matchGroups,
        selectedMatchDay,
    })
}

export default getMatchComponents
