import { FC, memo } from 'react'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

const CompetitionGamePlanError: FC = () => {
    return (
        <Font
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
            fontVariant="Body-L"
            fontFamilyVariant="Sport1-Regular"
            paddingX={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-none']}
            paddingBottom="spacing-7"
        >
            Fehler beim laden der Daten.
        </Font>
    )
}

export default memo(CompetitionGamePlanError)
