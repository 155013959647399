import { CommentaryClientModel } from '@sport1/types/sportData'
import { CommentaryHeadlineProps } from '@sport1/news-styleguide/Types'

const getHeadlineProps = ({
    type,
    period,
    scoreHome = 0,
    scoreAway = 0,
}: CommentaryClientModel): CommentaryHeadlineProps | undefined => {
    if (type === 'SUBSTITUTION') {
        return {
            text: 'Auswechslung',
            icon: type,
        }
    } else if (type === 'YELLOW_CARD') {
        return {
            text: 'Gelbe Karte',
            icon: type,
        }
    } else if (type === 'SECOND_YELLOW_CARD') {
        return {
            text: 'Gelb-Rote Karte',
            icon: type,
        }
    } else if (type === 'RED_CARD') {
        return {
            text: 'Rote Karte',
            icon: type,
        }
    } else if (type === 'GOAL') {
        return {
            text: `Tor (${scoreHome} : ${scoreAway})`,
            icon: type,
        }
    } else if (type === 'OWN_GOAL') {
        return {
            text: `Eigentor (${scoreHome} : ${scoreAway})`,
            icon: 'GOAL',
        }
    } else if (type === 'PENALTY_GOAL') {
        return {
            text: 'Elfmeter verwandelt',
            icon: 'GOAL',
        }
    } else if (type === 'PENALTY_MISS') {
        return {
            text: 'Elfmeter verschossen',
        }
    } else if (type === 'PENALTY_SAVED') {
        return {
            text: 'Elfmeter gehalten',
        }
    } else if (type === 'KICK_OFF' || type === 'START') {
        let periodText = ''
        if (period === 'SECOND_HALF') {
            periodText = ' 2. Halbzeit'
        } else if (period === 'FIRST_EXTRA_PERIOD') {
            periodText = ' 1. Halbzeit der Verlängerung'
        } else if (period === 'SECOND_EXTRA_PERIOD') {
            periodText = ' 2. Halbzeit der Verlängerung'
        }
        return {
            text: `Anpfiff${periodText}`,
            icon: 'WHISTLE',
        }
    } else if (type === 'HALF_TIME') {
        let periodText = ''
        if (period === 'FIRST_EXTRA_PERIOD') {
            periodText = ' der Verlängerung'
        }
        return {
            text: `Abpfiff 1. Halbzeit${periodText}`,
            icon: 'WHISTLE',
        }
    } else if (type === 'FULL_TIME' || type === 'END') {
        let periodText = ''
        if (type === 'END' && period === 'END_OF_MATCH') {
            return {
                text: 'Das Spiel ist zu Ende',
                icon: 'WHISTLE',
            }
        }
        if (period === 'FIRST_HALF') {
            periodText = ' 1. Halbzeit'
        } else if (period === 'SECOND_HALF') {
            periodText = ' 2. Halbzeit'
        } else if (period === 'SECOND_EXTRA_PERIOD') {
            periodText = ' der Verlängerung'
        }
        return {
            text: `Abpfiff${periodText}`,
            icon: 'WHISTLE',
        }
    } else if (type === 'VIDEO_REFEREE') {
        return {
            text: 'Videobeweis',
            icon: 'VIDEO_PROOF',
        }
    }
    return undefined
}

export default getHeadlineProps
