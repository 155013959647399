import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { MatchClientModel } from '@sport1/types/sportData'
import dayjs from 'dayjs'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import { BOTTOM_NAVIGATION_HEIGHT } from '@/layouts/Standard/BottomNavigation'
import scrollToTop from '@/utils/content/scrollToTop'
import { sport1Regular } from '@/helpers/fonts'

type CommentaryRefreshButtonProps = {
    checkForUpdate: () => void
    onPress: () => void
    isVisible: boolean
    match?: MatchClientModel
}

const LIVE_INTERVAL_TIME = 10000
const POST_INTERVAL_TIME = 30000

const BOTTOM_SPACE = 48

const isTodayByStartTime = (match: MatchClientModel) => {
    const startTime = match.actualStartTime || match.scheduledStartTime
    return startTime ? dayjs().isSame(dayjs(startTime), 'day') : false
}

const CommentaryRefreshButton: VFC<CommentaryRefreshButtonProps> = ({
    checkForUpdate,
    match,
    isVisible,
    onPress,
}) => {
    const interval = React.useRef<number>()

    React.useEffect(() => {
        if (!isVisible && match && isTodayByStartTime(match)) {
            interval.current = window.setInterval(
                checkForUpdate,
                match.isLive ? LIVE_INTERVAL_TIME : POST_INTERVAL_TIME
            )
        }
        return () => {
            clearInterval(interval.current)
        }
    }, [match, checkForUpdate, isVisible])

    return isVisible ? (
        <NonFlexingContainer
            position="sticky"
            zIndex={4}
            bottom={[
                BOTTOM_NAVIGATION_HEIGHT + BOTTOM_SPACE,
                BOTTOM_NAVIGATION_HEIGHT + BOTTOM_SPACE,
                BOTTOM_SPACE,
            ]}
            left={0}
            right={[0, 0, 0, 375 + 24]} // right column + margin
        >
            <NonFlexingContainer backgroundColor="onyx" borderRadius="24px" marginX="auto">
                <Touchable
                    onPress={() => {
                        onPress()
                        scrollToTop()
                    }}
                    testID="liveticker-refresh-button"
                >
                    <NonFlexingContainer paddingX="spacing-7" paddingY="spacing-6">
                        <Font
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                            fontSize={['font-size-3', 'font-size-3', 'font-size-5']}
                            lineHeight={['16px', '16px', '20px']}
                            color="pearl"
                        >
                            Neue Ereignisse
                        </Font>
                    </NonFlexingContainer>
                </Touchable>
            </NonFlexingContainer>
        </NonFlexingContainer>
    ) : null
}

export default CommentaryRefreshButton
