import { MatchGroupModel, MatchGroupsModel } from '@sport1/types/web'

const getCompetitionId = (
    matchGroups: MatchGroupsModel[] | MatchGroupModel[] = []
): string | undefined => {
    if (matchGroups[0] && 'matchGroups' in matchGroups[0]) {
        return matchGroups[0].matchGroups[0]?.matches[0]?.competition?.id
    }
    const groups = matchGroups as MatchGroupModel[]
    return groups[0]?.matches[0]?.competition?.id
}

export default getCompetitionId
