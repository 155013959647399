import { CommentaryAdModel, CommentaryModel } from '@sport1/types/web'

export type CommentaryData = {
    currentAdIndex: number
    hasFetched: boolean
    isRefreshing?: boolean
    hasNextPage: boolean
    commentaries: (CommentaryModel | CommentaryAdModel)[]
    updateAvailable: boolean
}

type InsertCommentary = {
    type: 'INSERT_COMMENTARY'
    commentaries: (CommentaryModel | CommentaryAdModel)[]
    hasNextPage: boolean
}

type ConcatCommentary = {
    type: 'CONCAT_COMMENTARY'
    hasNextPage: boolean
    commentaries: (CommentaryModel | CommentaryAdModel)[]
}

type RefreshCommentary = {
    type: 'REFRESH_COMMENTARY'
    isRefreshing?: boolean
}

type NewCommentaryAvailable = {
    type: 'NEW_COMMENTARY_AVAILABLE'
}

export type CommentaryStateAction =
    | InsertCommentary
    | ConcatCommentary
    | RefreshCommentary
    | NewCommentaryAvailable

export type CommentaryStateReducer = (
    state: CommentaryData,
    action: CommentaryStateAction
) => CommentaryData

export const initialCommentaryState: CommentaryData = {
    currentAdIndex: 0,
    hasFetched: false,
    isRefreshing: false,
    hasNextPage: true,
    commentaries: [],
    updateAvailable: false,
}

const commentaryStateReducer: CommentaryStateReducer = (state, action): CommentaryData => {
    switch (action.type) {
        case 'INSERT_COMMENTARY': {
            return {
                hasFetched: true,
                hasNextPage: true,
                isRefreshing: false,
                currentAdIndex: 0,
                commentaries: action.commentaries,
                updateAvailable: false,
            }
        }
        case 'CONCAT_COMMENTARY': {
            return {
                currentAdIndex: 0,
                hasFetched: true,
                isRefreshing: false,
                hasNextPage: action.hasNextPage,
                commentaries: [...state.commentaries, ...action.commentaries],
                updateAvailable: false,
            }
        }
        case 'REFRESH_COMMENTARY': {
            return {
                ...state,
                hasFetched: true,
                hasNextPage: true,
                isRefreshing: true,
                updateAvailable: false,
            }
        }
        case 'NEW_COMMENTARY_AVAILABLE': {
            return {
                ...state,
                updateAvailable: true,
            }
        }
    }
}

export default commentaryStateReducer
