import React, { VFC } from 'react'
import { LineupTeamClientModel } from '@sport1/types/sportData'
import SegmentedControl from '@sport1/news-styleguide/SegmentedControl'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { DisplayProp } from '@sport1/news-styleguide/Types'
import LineupField from '@/components/LineUp/Field'
import { getTeamName } from '@/components/SportEvent/utils'
import PlayerList from '@/components/LineUp/TeamInformation/PlayerList'
import { useLivetickerMatch } from '@/utils/liveticker/LivetickerMatchProvider'
import { getMatchLivetickerHref } from '@/utils/navigation/Navigator'
import { useNavigation } from '@/utils/navigation/Standard'
import { sport1Regular } from '@/helpers/fonts'

type MinifiedLineUpProps = {
    homeTeam: LineupTeamClientModel
    awayTeam: LineupTeamClientModel
    hasFormation: boolean
    display?: DisplayProp['display']
}

const MinifiedLineUp: VFC<MinifiedLineUpProps> = ({
    homeTeam,
    awayTeam,
    hasFormation,
    display,
}) => {
    const [selectedTeam, setSelectedTeam] = React.useState<'HOME' | 'AWAY'>('HOME')
    const { match } = useLivetickerMatch()
    const { navigation } = useNavigation()

    const toggleTeam = (index: number) => {
        setSelectedTeam(index === 0 ? 'HOME' : 'AWAY')
    }

    const team = selectedTeam === 'HOME' ? homeTeam : awayTeam

    return (
        <NonFlexingContainer
            display={display}
            marginBottom="spacing-7"
            testID="minified-line-up-container"
        >
            <NonFlexingContainer marginBottom="spacing-6">
                <CardHeadline
                    cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineS']}
                    text="Aufstellung"
                    href={
                        match
                            ? getMatchLivetickerHref({
                                  match,
                                  tag: navigation?.tag,
                                  page: 'aufstellung',
                              })
                            : undefined
                    }
                />
            </NonFlexingContainer>
            {hasFormation ? (
                <LineupField width="100%" homeTeam={homeTeam} awayTeam={awayTeam} />
            ) : (
                <>
                    <SegmentedControl
                        type="TWO"
                        firstLabel={getTeamName(homeTeam) || 'Heim'}
                        secondLabel={getTeamName(awayTeam) || 'Auswärts'}
                        onPress={toggleTeam}
                        hideAdLabel
                        testID="minified-line-up-segmented-control"
                    />
                    {team.firstEleven ? (
                        <PlayerList
                            headline="Startelf"
                            player={team.firstEleven}
                            paddingTop="spacing-7"
                            testID="minified-line-up-player-list"
                        />
                    ) : (
                        <Font
                            fontVariant="Body-L"
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                            fontFamilyVariant="Sport1-Regular"
                            paddingY="spacing-9"
                            textAlign="center"
                        >
                            Keine Aufstellung vorhanden.
                        </Font>
                    )}
                </>
            )}
        </NonFlexingContainer>
    )
}

export default MinifiedLineUp
