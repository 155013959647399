import { CommentaryClientModel } from '@sport1/types/sportData'
import { TickerPlayerProps } from '@sport1/news-styleguide/TickerPlayer'

const getPlayerStatusIcon = ({
    type,
    player1,
    player2,
}: Pick<
    CommentaryClientModel,
    'type' | 'player1' | 'player2'
>): TickerPlayerProps['playerStatusIcon'] => {
    if (player1 && type === 'SUBSTITUTION') {
        return 'IN'
    } else if (player2 && type === 'SUBSTITUTION') {
        return 'OUT'
    } else if (player1 && type === 'GOAL') {
        return 'GOAL'
    } else if (player2 && type === 'GOAL') {
        return 'ASSIST'
    }
}

export default getPlayerStatusIcon
