import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { getDateWithWeekday } from '@/utils/date'
import { sport1Regular } from '@/helpers/fonts'

type GameplanMatchDateProps = {
    date: string
}

const GameplanMatchDate: FC<GameplanMatchDateProps> = ({ date }) => (
    <NonFlexingContainer spaceBottom="small">
        <Font
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
            fontVariant="Label-L"
            fontFamilyVariant="Sport1-Regular"
            color="keshi-3"
            testID={`caption_date_${date}`}
        >
            {getDateWithWeekday(date)}
        </Font>
    </NonFlexingContainer>
)

export default GameplanMatchDate
