import React, { VFC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LineupTeamClientModel } from '@sport1/types/sportData'
import PlayerList from './PlayerList'
import Trainer from './Trainer'
import Substitution from './Substitution'

type StartingLineupProps = {
    team: LineupTeamClientModel
    showFirstEleven: boolean
    testID?: string
}

const LineupTeamInformation: VFC<StartingLineupProps> = ({ team, showFirstEleven, testID }) => (
    <NonFlexingContainer
        backgroundColor="pearl"
        paddingY="spacing-6"
        testID={`${testID}-container`}
    >
        {showFirstEleven && team.firstEleven ? (
            <PlayerList
                headline="Startelf"
                player={team.firstEleven}
                paddingTop="spacing-4"
                testID="player-list-starting"
            />
        ) : null}
        {team.substitution?.length && (
            <Substitution
                substitutions={team.substitution}
                marginTop={showFirstEleven && team.firstEleven ? 'spacing-7' : 'spacing-none'}
                testID="substitution"
            />
        )}
        {team.trainer ? <Trainer trainer={team.trainer} testID="trainer" /> : null}
        {team.bench ? (
            <PlayerList headline="Ersatzbank" player={team.bench} testID="player-list-bench" />
        ) : null}
    </NonFlexingContainer>
)

export default memo(LineupTeamInformation)
