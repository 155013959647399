import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

type GameplanMatchGroupProps = {
    groupName: string
}

const GameplanMatchGroup: FC<GameplanMatchGroupProps> = ({ groupName }) => (
    <NonFlexingContainer spaceBottom="small" spaceTop="standard">
        <Font
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
            fontVariant="Label-L"
            fontFamilyVariant="Sport1-Regular"
            color="keshi-3"
            testID={`caption_groupName_${groupName}`}
        >
            {`Gruppe ${groupName}`}
        </Font>
    </NonFlexingContainer>
)

export default GameplanMatchGroup
