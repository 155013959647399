import React, { FunctionComponent, memo } from 'react'
import { MatchStatisticModel } from '@sport1/types/web'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import Statistic from '@sport1/news-styleguide/MatchStatistic'

type Props = MatchStatisticModel & SystemSpacingsProps

const SMALL_VALUES_WINNER = [
    'TOTAL_YELLOW_CARD',
    'SENDING_OFF',
    'TOTAL_OFFSIDES',
    'BALL_LOST',
    'FOULS',
]

const extractValueAndUnit = (value: string): { value: number; unit?: string } => {
    const unitMatches = value.match('[^\\d]*$')
    return {
        value: parseFloat(value),
        unit: unitMatches ? unitMatches[0] : undefined,
    }
}

const MatchStatistic: FunctionComponent<Props> = ({ title, type, home, away, marginTop }) => {
    const { value: homeValue, unit: homeUnit } = extractValueAndUnit(home)
    const { value: awayValue } = extractValueAndUnit(away)
    let winner: 'DRAW' | 'HOME' | 'AWAY' = 'DRAW'
    if (homeValue !== awayValue) {
        if (!SMALL_VALUES_WINNER.includes(type)) {
            winner = homeValue > awayValue ? 'HOME' : 'AWAY'
        } else {
            winner = homeValue < awayValue ? 'HOME' : 'AWAY'
        }
    }
    return (
        <Statistic
            marginTop={marginTop}
            title={title}
            homeValue={homeValue}
            awayValue={awayValue}
            winner={winner}
            unit={homeUnit}
        />
    )
}

export default memo(MatchStatistic)
