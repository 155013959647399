import React, { Fragment, memo, useEffect, useState, VFC } from 'react'
import { AdPlacementType, MatchStatisticsModel } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import DisplayAd from '@/components/Ads/AdSlot/DisplayAd'
import EmptyMatchStatistics from '@/components/MatchStatistics/EmptyMatchStatistics'
import MatchStatisticGroup from '@/components/MatchStatistics/MatchStatisticsGroup'
import PlaceHolderBox from '@/components/PlaceHolderBox'
import { useCalculateAdSlotPlacement } from '@/hooks/useCalculateAdSlotPlacement'
import useFetch from '@/hooks/useFetch'

export type MatchStatisticsProps = {
    contentUrl: string
}

const MatchStatistics: VFC<MatchStatisticsProps> = ({ contentUrl }) => {
    const { data, error } = useFetch<MatchStatisticsModel>(contentUrl)
    const [adInfo, setAdInfo] = useState({})
    const { calculateName } = useCalculateAdSlotPlacement()

    useEffect(() => {
        setAdInfo(calculateName(AdPlacementType.MREC))
    }, [calculateName])

    if (!error && !data) {
        return <PlaceHolderBox height={500} />
    }

    if (error || (data && !data.matchStatistics?.length)) {
        return <EmptyMatchStatistics />
    }

    return (
        <NonFlexingContainer
            paddingX={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-none']}
            paddingBottom="spacing-4"
            minHeight={100}
            width="100%"
            backgroundColor="pearl"
            testID="match-statistics-container"
        >
            {data?.matchStatistics.map((group, index) => (
                <Fragment key={group.type}>
                    {group.type === 'GOALKEEPER' ? (
                        <DisplayAd
                            marginTop="spacing-7"
                            placeholderType={AdPlacementType.MREC}
                            {...adInfo}
                            disableBottomSpace
                        />
                    ) : null}
                    <MatchStatisticGroup
                        title={group.title}
                        statistics={group.statistics}
                        marginTop={index !== 0 ? 'spacing-9' : 'spacing-none'}
                    />
                </Fragment>
            ))}
        </NonFlexingContainer>
    )
}

export default memo(MatchStatistics)
