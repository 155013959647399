import React, { VFC } from 'react'
import SegmentedControl from '@sport1/news-styleguide/SegmentedControl'
import { LineupTeamClientModel } from '@sport1/types/sportData'
import { getTeamName } from '@/components/SportEvent/utils'
import LineupTeamInformation from '@/components/LineUp/TeamInformation'
import { useTracking } from '@/utils/tracking/TrackingProvider'

type TeamSelectionProps = {
    homeTeam: LineupTeamClientModel
    awayTeam: LineupTeamClientModel
    showFirstEleven: boolean
}

const TeamSelection: VFC<TeamSelectionProps> = ({ homeTeam, awayTeam, showFirstEleven }) => {
    const [selectedTeam, setSelectedTeam] = React.useState<'HOME' | 'AWAY'>('HOME')
    const { trackIvwView, trackIvwInfo } = useTracking()

    const toggleTeam = (index: number) => {
        setSelectedTeam(index === 0 ? 'HOME' : 'AWAY')
        trackIvwInfo({
            ivw_context: 'toggle',
        })
        trackIvwView()
    }

    return (
        <>
            <SegmentedControl
                type="TWO"
                firstLabel={getTeamName(homeTeam) || 'Heim'}
                secondLabel={getTeamName(awayTeam) || 'Auswärts'}
                onPress={toggleTeam}
                hideAdLabel
            />
            <LineupTeamInformation
                team={selectedTeam === 'HOME' ? homeTeam : awayTeam}
                showFirstEleven={showFirstEleven}
                testID="team-selection"
            />
        </>
    )
}

export default TeamSelection
