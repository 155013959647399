import React, { ReactElement } from 'react'
import { MatchGroupModel } from '@sport1/types/web'
import PlayOffMatchStatus from '@sport1/news-styleguide/PlayOffMatchStatus'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import GameplanMatch from '../Match'
import GameplanMatchGroup from '../MatchGroup'
import { GetMatchItemsParams } from './getMatchComponents'
import { getDateWithWeekday } from '@/utils/date'
import { sport1Regular } from '@/helpers/fonts'

const hasWonPlayoffs = (playoffScore?: number, bestOfSets?: number) =>
    playoffScore && bestOfSets ? playoffScore >= (bestOfSets + 1) / 2 : false

const getPlayoffGameplanMatchItems = ({
    matchGroups,
    selectedMatchDay,
}: Pick<GetMatchItemsParams, 'matchGroups' | 'selectedMatchDay'>): ReactElement[] => {
    const matchElements: ReactElement[] = []
    const groups = matchGroups as MatchGroupModel[]
    groups.forEach(
        (
            { homePlayoffScore, awayPlayoffScore, homeTeam, awayTeam, groupName, matches = [] },
            index
        ) => {
            if (groupName) {
                matchElements.push(
                    <GameplanMatchGroup key={`${groupName}-${index}`} groupName={groupName} />
                )
            }
            if (selectedMatchDay) {
                matchElements.push(
                    <NonFlexingContainer spaceY="small">
                        <Font
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                            fontVariant="Label-L"
                            fontFamilyVariant="Sport1-Regular"
                            color="keshi-3"
                        >
                            {selectedMatchDay.label}
                        </Font>
                    </NonFlexingContainer>
                )
            }
            const lastMatch = matches[matches.length - 1]
            if (lastMatch) {
                const { competition, matchInfo } = lastMatch
                const bestOfSets = matchInfo?.bestOfSets
                const bestOfSetsString = bestOfSets ? `(Best of ${bestOfSets})` : ''
                matchElements.push(
                    <FlexingContainer spaceBottom="standard">
                        <PlayOffMatchStatus
                            headline={`Playoff-Stand ${bestOfSetsString}`}
                            competitionImageUrl={competition?.imageUrl}
                            homeCompetitor={{
                                name: homeTeam?.shortName || homeTeam?.name || '',
                                logoUrl: homeTeam?.imageUrl,
                                isBold: hasWonPlayoffs(homePlayoffScore, bestOfSets),
                            }}
                            awayCompetitor={{
                                name: awayTeam?.shortName || awayTeam?.name || '',
                                logoUrl: awayTeam?.imageUrl,
                                isBold: hasWonPlayoffs(awayPlayoffScore, bestOfSets),
                            }}
                            homeScore={homePlayoffScore || 0}
                            awayScore={awayPlayoffScore || 0}
                        />
                    </FlexingContainer>
                )
            }
            matches.map((match, index) => {
                matchElements.push(
                    <NonFlexingContainer spaceTop={index === 0 ? undefined : 'standard'}>
                        <GameplanMatch
                            key={`gameplan-match-${match.id}`}
                            match={match}
                            headline={{
                                text: `Spiel ${index + 1}${
                                    match.scheduledStartTime
                                        ? `, ${getDateWithWeekday(match.scheduledStartTime)}`
                                        : ''
                                }`,
                                hideTagImage: true,
                                imageUrl: '',
                            }}
                        />
                    </NonFlexingContainer>
                )
            })
        }
    )
    return matchElements
}

export default getPlayoffGameplanMatchItems
