import React, { FC } from 'react'
import TeamPlayer, { WarningCard } from '@sport1/news-styleguide/TeamPlayer'
import { LineupTeamClientModel, PlayerWithEventsClientModel } from '@sport1/types/sportData'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import getAbridgedPlayerName from '../../utils/getAbridgedPlayerName'
import { createPlayerInfoHref } from '@/utils/navigation/Navigator'

interface LineupPlayerProps
    extends SystemSpacingsProps,
        Pick<LineupTeamClientModel, 'teamKitColor'> {
    player: PlayerWithEventsClientModel
    testID?: string
}

const LineupPlayer: FC<LineupPlayerProps> = ({ teamKitColor, player, paddingX, testID }) => {
    const { jerseyNumber, events, imageUrl } = player
    const playerName = getAbridgedPlayerName(player)
    return (
        <TeamPlayer
            key={playerName}
            teamColor={teamKitColor?.base || 'keshi-3'}
            playerName={playerName}
            jerseyNumber={jerseyNumber ? `${jerseyNumber}` : undefined}
            playerImageUrl={imageUrl}
            warningCardList={events?.filter(event => event.includes('CARD')) as WarningCard[]}
            goalNumber={events?.filter(event => event.includes('GOAL')).length || undefined}
            additionalInfoIconName={
                events?.some(event => event === 'SUBSTITUTE_OUT') ? 'substitution' : undefined
            }
            paddingX={paddingX}
            href={createPlayerInfoHref(player)}
            testID={testID}
        />
    )
}

export default LineupPlayer
