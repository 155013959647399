import React, { FunctionComponent, useCallback, useRef } from 'react'
import { GenericComponentProps, LayoutProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import DisplayAd from '@/components/Ads/AdSlot/DisplayAd'
import Commentary from '@/components/LivetickerCommentaries/Commentary'
import EmptyCommentaries from '@/components/LivetickerCommentaries/Empty'
import CommentaryRefreshButton from '@/components/LivetickerCommentaries/RefreshButton'
import useCommentaryData from '@/components/LivetickerCommentaries/utils/useCommentaryData'
import PlaceHolderBox from '@/components/PlaceHolderBox'
import { getPlacementSuffix } from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import useInViewport from '@/hooks/useInViewport'
import { useLivetickerMatch } from '@/utils/liveticker/LivetickerMatchProvider'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useMobile } from '@/hooks/useMobile'

const LivetickerCommentaries: FunctionComponent<
    Pick<GenericComponentProps, 'contentUrl'> & { layout?: LayoutProps }
> = ({ contentUrl, layout }) => {
    const { match, refreshLivetickerMatch } = useLivetickerMatch()
    const {
        hasFetched,
        commentaries,
        hasNextPage,
        isRefreshing,
        fetchNextCommentaries,
        refreshCommentaries,
        checkForNewCommentaries,
        updateAvailable,
    } = useCommentaryData({ url: contentUrl, match })
    const { trackIvwView, trackIvwInfo } = useTracking()
    const ref = useRef<HTMLDivElement>(null)
    const isInViewPort = useInViewport(ref)
    const { isMobile } = useMobile()
    const tickerPlacement = isMobile ? 'topmobile' : 'rectangle'
    const initialPlacementCount =
        layout?.components.filter(c => c.type === 'AD' && c.id?.startsWith(tickerPlacement))
            .length || 0
    const maxPlacementCount = 5

    let tickerPlacementCounter = 0
    let firstEncounteredAd = false

    const updateLiveticker = useCallback(() => {
        trackIvwView()
        trackIvwInfo()
        refreshCommentaries()
        refreshLivetickerMatch()
    }, [refreshCommentaries, refreshLivetickerMatch, trackIvwInfo, trackIvwView])

    React.useEffect(() => {
        if (!isRefreshing && isInViewPort) {
            fetchNextCommentaries()
        }
    }, [isInViewPort, fetchNextCommentaries, isRefreshing])

    if (hasFetched && !commentaries?.length) {
        return <EmptyCommentaries />
    }

    return (
        <>
            {!hasFetched && !commentaries?.length ? (
                <PlaceHolderBox height={500} />
            ) : (
                commentaries?.map((item, index) => {
                    if (item.type === 'COMMENTARY') {
                        return <Commentary key={index} commentary={item.commentary} />
                    }

                    if (maxPlacementCount <= initialPlacementCount) {
                        return null
                    }

                    const placementSuffix = getPlacementSuffix(
                        (tickerPlacementCounter % (maxPlacementCount - initialPlacementCount)) +
                            (initialPlacementCount + 1)
                    )
                    tickerPlacementCounter++
                    const targeting = !firstEncounteredAd ? { eventad: ['1'] } : undefined
                    firstEncounteredAd = true

                    return (
                        <NonFlexingContainer
                            key={index}
                            marginX={['spacing-6', 'spacing-6', 'spacing-6', 'spacing-none']}
                            testID="liveticker-commentaries-ad-container"
                        >
                            <DisplayAd
                                width="100%"
                                placement={tickerPlacement}
                                placementId={`${tickerPlacement}${placementSuffix}`}
                                endless
                                targeting={targeting}
                            />
                        </NonFlexingContainer>
                    )
                })
            )}
            {hasNextPage ? (
                <div ref={ref} data-testid="liveticker-commentaries-end-detector" />
            ) : null}
            <CommentaryRefreshButton
                match={match}
                checkForUpdate={checkForNewCommentaries}
                onPress={updateLiveticker}
                isVisible={updateAvailable}
            />
        </>
    )
}

export default LivetickerCommentaries
