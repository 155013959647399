import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import React, { FC, memo } from 'react'
import { LineupTeamClientModel } from '@sport1/types/sportData'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import LineupPlayer from '../Player'

type FormationHalfProps = Required<Pick<LineupTeamClientModel, 'formation'>> &
    Pick<LineupTeamClientModel, 'teamKitColor'> & {
        shouldReversePlayers?: boolean
        position: 'relative' | 'absolute'
        top?: number
        left?: number
        height?: number
        width?: ResponsiveType<PixelType | number | PercentageType>
        zIndex?: number
    }

const FormationHalf: FC<FormationHalfProps> = ({
    shouldReversePlayers,
    position,
    top,
    left,
    width,
    height,
    zIndex,
    teamKitColor,
    formation,
}) => {
    return (
        <NonFlexingContainer
            position={position}
            top={top}
            left={left}
            zIndex={zIndex}
            width={width}
            height={height}
            testID="formation-half-main-container"
        >
            {formation.map((playersWithEvents, index) => (
                <FlexingContainer
                    key={`formation_half_${index}`}
                    horizontal
                    testID={`formation-half-container-${index}`}
                >
                    {(shouldReversePlayers
                        ? [...playersWithEvents].reverse()
                        : playersWithEvents
                    ).map(player => (
                        <LineupPlayer
                            key={`${player.id}`}
                            player={player}
                            teamKitColor={teamKitColor}
                            testID={`formation-half-player-${index}`}
                        />
                    ))}
                </FlexingContainer>
            ))}
        </NonFlexingContainer>
    )
}

export default memo(FormationHalf)
