import React, { FC, memo, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import BaseLineupField from '@sport1/news-styleguide/LineupField'
import { LineupTeamClientModel } from '@sport1/types/sportData'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import TeamFormation from './TeamFormation'
import GoalKeeper from './GoalKeeper'
import FormationHalf from './FormationHalf'

type LineupFieldProps = {
    homeTeam: LineupTeamClientModel
    awayTeam: LineupTeamClientModel
    width?: ResponsiveType<number | PixelType | PercentageType>
}

const LINEUP_PADDING = 16
const GOAL_HEIGHT = 70
const EXCEEDING_HEIGHT_ADDITION = 100
const DEFAULT_LINEUP_HEIGHT = 668

const LineupField: FC<LineupFieldProps> = ({
    homeTeam,
    awayTeam,
    width = ['100%', '100%', 375],
}) => {
    const lineupFieldHeight = useMemo(() => {
        const homeSystemSplit = homeTeam.system?.split('-').length || 0
        const awaySystemSplit = awayTeam.system?.split('-').length || 0
        const maxSplit = Math.max(homeSystemSplit, awaySystemSplit)
        // If we have a bigger formation than 3 we want to make the field bigger by 100px * formation length - 3
        return Math.max(maxSplit - 3, 0) * EXCEEDING_HEIGHT_ADDITION + DEFAULT_LINEUP_HEIGHT
    }, [awayTeam.system, homeTeam.system])
    const homeTeamGoalkeeper = useMemo(
        () =>
            homeTeam.formation && homeTeam.formation.length > 0
                ? homeTeam.formation?.[0]?.[0]
                : undefined,
        [homeTeam.formation]
    )
    const awayTeamGoalkeeper = useMemo(
        () =>
            awayTeam.formation && awayTeam.formation.length > 0
                ? awayTeam.formation?.[0]?.[0]
                : undefined,
        [awayTeam.formation]
    )

    return (
        <NonFlexingContainer
            position="relative"
            height={lineupFieldHeight}
            width={width}
            testID="line-up-field-container"
        >
            <BaseLineupField
                width="100%"
                height={lineupFieldHeight}
                centerImageUrl={homeTeam.imageUrl?.replace('20x20', '200x200')} // TODO: remove replace when https://sport1digital.atlassian.net/browse/ML-5621 has been finished
            />
            {homeTeam.system ? (
                <TeamFormation
                    position="absolute"
                    top={8}
                    left={6}
                    zIndex={2}
                    teamImageUrl={homeTeam.imageUrl}
                    system={homeTeam.system}
                    testID="line-up-team-formation-home"
                />
            ) : null}
            {awayTeam.system ? (
                <TeamFormation
                    position="absolute"
                    bottom={8}
                    left={6}
                    zIndex={2}
                    teamImageUrl={awayTeam.imageUrl}
                    system={awayTeam.system}
                    testID="line-up-team-formation-away"
                />
            ) : null}
            {awayTeamGoalkeeper ? (
                <GoalKeeper
                    position="absolute"
                    bottom={31}
                    width="100%"
                    goalkeeper={awayTeamGoalkeeper}
                    teamKitColor={awayTeam.teamKitColor}
                />
            ) : null}
            {homeTeamGoalkeeper ? (
                <GoalKeeper
                    position="absolute"
                    top={25}
                    width="100%"
                    goalkeeper={homeTeamGoalkeeper}
                    teamKitColor={homeTeam.teamKitColor}
                />
            ) : null}
            {homeTeam.formation ? (
                <FormationHalf
                    position="absolute"
                    top={LINEUP_PADDING * 2 + GOAL_HEIGHT}
                    left={0}
                    zIndex={2}
                    width="100%"
                    height={lineupFieldHeight / 2 - GOAL_HEIGHT - LINEUP_PADDING * 2}
                    teamKitColor={homeTeam.teamKitColor}
                    formation={[...homeTeam.formation].slice(1)}
                />
            ) : null}
            {awayTeam.formation ? (
                <FormationHalf
                    shouldReversePlayers
                    position="absolute"
                    top={lineupFieldHeight / 2 + LINEUP_PADDING}
                    left={0}
                    zIndex={2}
                    width="100%"
                    height={lineupFieldHeight / 2 - GOAL_HEIGHT - LINEUP_PADDING * 2}
                    teamKitColor={awayTeam.teamKitColor}
                    formation={[...awayTeam.formation].slice(1).reverse()}
                />
            ) : null}
        </NonFlexingContainer>
    )
}

export default memo(LineupField)
