import React, { FunctionComponent, memo } from 'react'
import { MatchStatisticsGroupModel } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import MatchStatistic from '../MatchStatistic'

type Props = Omit<MatchStatisticsGroupModel, 'type'> & SystemSpacingsProps

const MatchStatisticGroup: FunctionComponent<Props> = ({ title, statistics, marginTop }) => {
    return (
        <NonFlexingContainer testID="match-statistic-group-container" marginTop={marginTop}>
            <CardHeadline
                text={title}
                cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                testID="match-statistic-headline"
            />
            {statistics.map(({ title, type, home, away }, index) => (
                <MatchStatistic
                    key={`${type}_${home}_${away}`}
                    title={title}
                    type={type}
                    home={home}
                    away={away}
                    marginTop={index === 0 ? 'spacing-7' : 'spacing-9'}
                />
            ))}
        </NonFlexingContainer>
    )
}

export default memo(MatchStatisticGroup)
