import React, { VFC } from 'react'
import Font from '@sport1/news-styleguide/Font'
import { MatchClientModel } from '@sport1/types/sportData'
import { getDateFromZeroTimezoneStamp, getTimeWithHourAndMinutes } from '@/utils/date'
import { useLivetickerMatch } from '@/utils/liveticker/LivetickerMatchProvider'
import { sport1Regular } from '@/helpers/fonts'

const getCommentaryEmptyText = (match?: MatchClientModel): string => {
    let emptyMessage = 'Das Spiel hat noch nicht angefangen.'
    const { isLive, scheduledStartTime } = match || {}
    if (scheduledStartTime && isLive) {
        emptyMessage = `Das Spiel ist um ${getTimeWithHourAndMinutes(
            scheduledStartTime
        )} Uhr gestartet.`
    } else if (scheduledStartTime) {
        emptyMessage = `Das Spiel startet am ${getDateFromZeroTimezoneStamp(
            scheduledStartTime
        )} um ${getTimeWithHourAndMinutes(scheduledStartTime)} Uhr.`
    }
    return emptyMessage
}

const EmptyCommentaries: VFC = () => {
    const { match } = useLivetickerMatch()
    return (
        <Font
            fontVariant="Body-L"
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
            fontFamilyVariant="Sport1-Regular"
            paddingX={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-none']}
            paddingBottom="spacing-7"
            textAlign="center"
        >
            {getCommentaryEmptyText(match)}
        </Font>
    )
}

export default EmptyCommentaries
